import classnames from 'classnames';
import isNil from 'lodash/isNil';
import { forwardRef } from 'react';

import ImageSuggestionsContainer from './ImageSuggestionsContainer/ImageSuggestionsContainer';

import styles from './ResultsDropdown.module.scss';

type ResultsDropdownProps = {
  [key: string]: unknown;
  children: unknown[] | null;
  closeSuggestionsContainer: () => void;
  isShowing: boolean;
};

export const ResultsDropdown = (allProps: ResultsDropdownProps, ref: any) => {
  const {
    children,
    closeSuggestionsContainer,
    isShowing,
    ...containerProps
  } = allProps;
  const classNames = classnames(styles.root, {
    [styles.hidden]: !isShowing,
  });
  const imageSuggestionsContainerProps = {
    closeSuggestionsContainer,
    isShowing,
  };

  const renderText = () => {
    if (isShowing) {
      return (
        <div aria-live="polite" role="alert">
          Sorry, no suggestions found.
        </div>
      );
    } else {
      return <div>Hit return to search.</div>;
    }
  };

  return (
    <div {...containerProps} className={classNames} ref={ref}>
      <div className={styles.resultsContainer}>
        <div className={styles.textSuggestions}>
          {isNil(children) ? renderText() : children}
        </div>
        <div className={styles.imageSuggestions}>
          <ImageSuggestionsContainer {...imageSuggestionsContainerProps} />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(ResultsDropdown);
