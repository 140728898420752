import { ContextType } from 'react';
import { HighlightProps } from 'react-instantsearch-core';

import { ProductOptionTypeEnum, VariantFilterables } from 'data/graphql/types';
import { SearchResultsProps } from 'types/algolia';
import { Category } from 'types/api';
import { AlgoliaFacetValueEntry } from 'types/contentful';
import {
  AssetType,
  Brand,
  ExplorerContentOwnerType,
  OfferDetails,
} from 'types/generated/api';

import AlgoliaContext from '../context/AlgoliaContext';

export interface AlgoliaProductExplorer {
  countCollection: number;
  countLiveClip: number;
  countPost: number;
  topPosts: AlgoliaExplorerPost[];
}

export interface AlgoliaExplorerAsset {
  id: string;
  type: AssetType;
  url: string;
}

export interface AlgoliaExplorerPost {
  assets: AlgoliaExplorerAsset[];
  ownerId: string;
  postId: string;
}

export type AlgoliaSearchParameters = {
  analytics?: boolean;
  clickAnalytics?: boolean;
  distinct?: number | boolean;
  enableRules?: boolean;
  facetFilters?: string;
  filters?: string;
  getRankingInfo?: boolean;
  hitsPerPage?: number;
  optionalFilters?: string;
  page?: number | string;
  query?: string;
  ruleContexts?: string[];
};

export enum ITEM_TYPES {
  ALGOLIA_PRODUCT = 'ALGOLIA_PRODUCT',
  ALGOLIA_PROFILE = 'ALGOLIA_PROFILE',
  ALGOLIA_SEARCHABLE_NAVIGATION = 'ALGOLIA_SEARCHABLE_NAVIGATION',
}

export type AlgoliaSearchResult = {
  hits: ProductHit[] | SearchableNavigationHit[] | ProfileHit[];
  index: string;
};

export type ParsedHitProps = {
  isHighlighted: boolean;
  value: string;
};

export type HighlightSearchableNavigationProps = HighlightProps & {
  hit: SearchableNavigationHit;
  itemType: ITEM_TYPES.ALGOLIA_SEARCHABLE_NAVIGATION;
};

export type HighlightProductProps = HighlightProps & {
  hit: ProductHit;
  itemType: ITEM_TYPES.ALGOLIA_PRODUCT;
};

export type HighlightProfileProps = HighlightProps & {
  hit: ProfileHit;
  itemType: ITEM_TYPES.ALGOLIA_PROFILE;
};

export type ProductHit = {
  product: {
    brandEntity?: Brand;
    brandSlug: string;
    familySlug: string;
    sid: string;
    slug: string;
    title: string;
  };
  productSid: string;
};

export type ProfileHit = {
  displayName: string;
  objectID: string;
  ownerName: string;
  type: ExplorerContentOwnerType;
};

export type SearchableNavigationHit = {
  entityType: string;
  name: string;
  path: string;
  slug: string;
};

export type InstantSearchProps = {
  indexName: string;
  onSearchStateChange?: (searchState: SearchStateProps) => void;
  root: { Root: string; props: Record<string, unknown> };
  searchClient: ContextType<typeof AlgoliaContext>;
  searchState?: Record<string, unknown> | undefined;
};

export type MultiIndexAutoCompleteProps = {
  currentRefinement: string;
  hits: {
    hits: Array<Record<string, unknown>>;
    index: string;
  };
  refine: void;
};

export enum RefinementListTypeEnum {
  COLOR = 'color',
  FINAL_CATEGORY = 'final category',
  HIERARCHICAL = 'hierarchical',
  MULTISELECT = 'multiselect',
  RANGE = 'range',
}

export type RefinementListProps = {
  allCategories?: Category[];
  attribute: string;
  categoryMeta?: Category;
  colorFamilyName?: string;
  firstFullLoad?: boolean;
  handleSetFacetCount?: (newFacetCount: number) => void;
  isMobile?: boolean;
  isNestedHeader?: boolean;
  items: RefinementProps[];
  linkType?: string;
  refine: (value: string[]) => void;
  searchPlaceholderText?: string;
  sortOrder?: AlgoliaFacetValueEntry[];
  startsExpanded?: boolean;
  syntheticItems?: Array<{
    active?: boolean;
    displayText: string;
    value: string;
  }>;
  title: string;
  type?: RefinementListTypeEnum;
};

export type RefinementProps = {
  count: number;
  isRefined: boolean;
  label: string;
  value: string[];
};
/**
 * Algolia SearchState props as passed to the `<Configure>` component.
 * This is not a complete type definition. Feel free to add more k/v pairs
 * as you use this type with Algolia's `<Configure>` component and `searchState`
 *
 */
export type SearchStateProps = {
  configure: {
    /**
     * Contains any filters per Algolia documentation.
     * Official documentation: https://www.algolia.com/doc/api-reference/api-parameters/filters/
     *
     */
    filters?: string;
  };
  page: number;
  refinementList: {
    [key: string]: string[];
  };
};

export type StateResultsProps = {
  error: Record<string, unknown>;
  isSearchStalled: boolean;
  searchResults: SearchResultsProps;
  searchState: SearchStateProps;
  searching: boolean;
  searchingForFacetValues: boolean;
};

interface Image {
  url: string;
}

// tslint:disable-next-line: interface-name
export interface ProductVariant {
  _rankingInfo: {
    filters: number;
    firstMatchedWord: number;
    geoDistance: number;
    geoPrecision: number;
    nbExactWords: number;
    nbTypos: number;
    promoted: boolean;
    proximityDistance: number;
    userScore: number;
    words: number;
  };
  averageRating?: number | string;
  colorFamilyCode?: string;
  colorFamilyName?: string;
  compareAtPrice: number;
  currencyCode: string;
  eligiblePromotions: string[];
  explorer: AlgoliaProductExplorer;
  filterables: VariantFilterables;
  galleryImages: Image[];
  inStock: boolean;
  isDiscountable: boolean;
  modelImages: Image[];
  objectID: string;
  offerDetails: OfferDetails;
  optionValues: OptionValue[];
  plpGroupId: string;
  price: number;
  product: {
    brand: string;
    brandEntity?: Brand;
    brandSlug: string;
    categories: string[] | string[][];
    family: string;
    familySlug: string;
    hierarchicalCategories: {
      lvl0: string[];
      lvl1: string[];
      lvl2: string[];
      lvl3: string[];
    };
    plpOptions: PlpOption[];
    sid: string;
    slug: string;
    title: string;
  };
  productImages: Image[];
  productSid: string;
  reviewCount?: number | string;
  selectedOptions?: Array<{ name: string; value: string }>;
  shoppingStatus: ShoppingStatus;
  sid: string;
  sku: string;
  title: string;
}

export type OptionValue = {
  compareAtPrice?: number;
  fullSid: string;
  inStock: boolean;
  isDiscountable?: boolean;
  offerDetails: OfferDetails;
  price: number;
  sid: string;
  sku: string;
  values: OptionValueValue[];
};

export type OptionValueValue = {
  label: string;
  name: string;
  value: string;
};

export type PlpOption = {
  label: string;
  name: string;
  primary: boolean;
  type: ProductOptionTypeEnum;
  values: PlpOptionValue[];
};

export type PlpOptionValue = PlpColorOptionValue | PlpStringOptionValue;

export type PlpColorOptionValue = {
  brandColor: string;
  colorFamily: string;
  label: string;
  preview: Preview;
  sortOrder: number;
  swatchColorCode: string;
  value: string;
};

export type PlpStringOptionValue = {
  label: string;
  preview: Preview;
  sortOrder: number;
  value: string;
};

export type Preview = {
  images: string[];
};

export enum ShoppingStatus {
  ACCEPT_PREORDERS = 'ACCEPT_PREORDERS',
  COMING_SOON = 'COMING_SOON',
  DISCONTINUED = 'DISCONTINUED',
  SELLABLE = 'SELLABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}
